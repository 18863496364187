// Generated by Framer (76f10ba)

import { addFonts, cx, CycleVariantState, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {g4Bpx76bn: {hover: true}};

const cycleOrder = ["g4Bpx76bn"];

const variantClassNames = {g4Bpx76bn: "framer-v-12mvace"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {type: "spring", ease: [0.44, 0, 0.56, 1], duration: 0.3, delay: 0, stiffness: 500, damping: 60, mass: 1}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "g4Bpx76bn", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const { variants, baseVariant, gestureVariant, classNames, transition, setVariant, setGestureState } = useVariantState({defaultVariant: "g4Bpx76bn", variant, transitions, variantClassNames, enabledGestures, cycleOrder})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-K3Sjz", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<motion.div {...restProps} className={cx("framer-12mvace", className)} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} layoutId={"g4Bpx76bn"} transition={transition} layoutDependency={layoutDependency} data-framer-name={"Variant 1"} ref={ref} variants={{"g4Bpx76bn-hover": {backgroundColor: "rgb(143, 143, 143)"}}}><RichText className={"framer-1czdxah"} style={{"--framer-paragraph-spacing": "0px", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", whiteSpace: "pre", "--extracted-1w3ko1f": "rgba(0, 0, 0, 0.6)"}} transformTemplate={(_, t) => `translate(-50%, -50%) ${t}`} layoutId={"DlpocCiR1"} transition={transition} layoutDependency={layoutDependency} fonts={["GF;Outfit-300"]} withExternalLayout={true} verticalAlignment={"top"} __fromCanvasComponent={true} __htmlStructure={"<p><span style=\"--framer-font-family:&quot;Outfit&quot;, serif; --framer-font-style:normal; --framer-font-weight:300; --font-selector:R0Y7T3V0Zml0LTMwMA==; --framer-text-color:var(--extracted-1w3ko1f); --framer-letter-spacing:-0.5px;\">{{ text-placeholder }}</span></p>"} htmlFromDesign={"<p><span style=\"--framer-font-family:&quot;Outfit&quot;, serif; --framer-font-style:normal; --framer-font-weight:300; --font-selector:R0Y7T3V0Zml0LTMwMA==; --framer-text-color:var(--extracted-1w3ko1f); --framer-letter-spacing:-0.5px;\">Home</span></p>"}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-K3Sjz [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-K3Sjz * { box-sizing: border-box; }", ".framer-K3Sjz .framer-12mvace { position: relative; width: 121px; height: 35px; }", ".framer-K3Sjz .framer-1czdxah { position: absolute; width: auto; height: auto; left: 50%; top: 51%; flex: none; white-space: pre; }", ".framer-K3Sjz.framer-v-12mvace .framer-12mvace { cursor: pointer; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 35
 * @framerIntrinsicWidth 121
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"m_kC_VXwN":{"layout":["fixed","fixed"]}}}
 */
const FramerVeho9c3b7: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerVeho9c3b7;

FramerVeho9c3b7.displayName = "Menu Item";

FramerVeho9c3b7.defaultProps = {width: 121, height: 35};

addFonts(FramerVeho9c3b7, [{url: "https://fonts.gstatic.com/s/outfit/v6/QGYyz_MVcBeNP4NjuGObqx1XmO1I4W61C4G-EiAou6Y.ttf", family: "Outfit", style: "normal", weight: "300", moduleAsset: {url: "https://fonts.gstatic.com/s/outfit/v6/QGYyz_MVcBeNP4NjuGObqx1XmO1I4W61C4G-EiAou6Y.ttf", localModuleIdentifier: "local-module:canvasComponent/Veho9c3b7:default"}}])